import { t } from "i18next";
import { Container, SocialMenu } from "../components";
import { history } from "src/stores";

export const Home = (props: any) => {
  return (
    <Container>
      <div className="flex flex-col justify-center items-center flex-1 h-full relative z-[5] text-center">
        <h2 className="text-[32px] tracking-[5px] leading-[36px]">
          How I <span className="glowing-light">SLAP</span> Your Mother
        </h2>
        <div className="flex flex-col justify-end">
          <button
            className="mt-[50px]"
            onClick={() => history.push("/airdrop-registration")}
          >
            {t("button.registration")}
          </button>
        </div>
      </div>

      {/* Social Menu */}
      <div className="fixed bottom-[30px] right-[30px]">
        <SocialMenu />
      </div>

      {/* New Meme Block */}
    </Container>
  );
};

import { Container } from "../components";

const ROADMAP = [
  {
    title: "Whitelist Airdrop",
    desc: "In this phase, a whitelist airdrop will be conducted, allowing early supporters to receive a certain amount of $SLAP tokens for free as a gesture of appreciation.",
  },
  {
    title: "Fair Launch on Uniswap",
    desc: "After the whitelist airdrop, the $SLAP token will be launched on Uniswap. This will provide an opportunity for anyone interested to purchase $SLAP tokens and become part of the community.",
  },
  {
    title: "Meme Generation using $SLAP",
    desc: "The project will focus on creating a meme culture around $SLAP tokens. Users will be encouraged to generate memes NFT through our SLAP Engine, spreading laughter and engaging the community.",
  },
  {
    title: "More Coming",
    desc: "The project is not ended here, more features and incentives will be launching",
  },
];

export const Roadmap = (props: any) => {
  return (
    <Container>
      <h2 className="page-title">
        "<span className="glowing-light">SLAP</span>, Jack! I'll never let go of
        the <span className="glowing-light">SLAP</span>!"
      </h2>
      <p className="mt-[30px]">
        The roadmap for the "How I SLAP your mother" project includes the
        following milestones.
      </p>
      <div className="mt-[30px]">
        {ROADMAP.map((item, i) => {
          return (
            <div
              className={`mb-[15px] border-[1px] rounded-[10px] flex flex-row min-h-[120px] items-center p-[30px]`}
              key={i}
              style={{
                ...(i === 0 && {
                  boxShadow: "0px 0px 10px 2px #FFF500",
                }),
              }}
            >
              {/* Title + Description */}
              <div className="flex flex-col">
                <h2>{item.title}</h2>
                <p className="mt-[5px]">{item.desc}</p>
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export const enTranslation = {
  home: {
    title: "The New Digital Era",
    liner:
      "We are pioneering the transformation from Web2 to Web3, crafting innovative solutions in #socialFi, #gameFi, #DeFi, and offering expert consultancy services.",
  },
  button: {
    slapSomeone: "Go SLAP Someone",
    registration: "Register for free Airdrop",
  },
  menu: {
    home: "Home",
    about: "About",
    howToBuy: "How to Buy",
    tokenomics: "Tokenomics",
    roadmap: "Roadmap",
  },
};

import { useEffect } from "react";
import { useLocation } from "react-router";
import { Header } from "./header";

export const Container = (props: any) => {
  const { children } = props;
  const location = useLocation();
  const isHome = window.location.pathname === "/";

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <div className="bg-gradient-to-b from-[#101010] to-[#252935] min-h-screen h-screen overflow-hidden">
      <Header />
      <div
        className="pt-[100px] md:pt-[140px] pb-[100px] px-[30px] h-full "
        style={{
          ...(isHome && {
            backgroundImage: "url('/assets/batman-wallpaper.jpeg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            paddingTop: 100,
            paddingBottom: 100,
          }),
          ...(!isHome && {
            maxWidth: 800,
            margin: "0 auto",
          }),
        }}
      >
        <div className="overflow-y-auto h-full">{children}</div>
      </div>
    </div>
  );
};

import { useWeb3Modal } from "@web3modal/react";
import {
  Container,
  LoadingIcon,
  openSuccessNotification,
  openWarningNotification,
} from "../components";
import { useAccount, useDisconnect } from "wagmi";
import { useCallback, useEffect, useState } from "react";
import { useRegistrationStore } from "src/stores";

export const Register = (props: any) => {
  const [totalRegistration, setTotalRegistration] = useState(null);
  const [allLoaded, setAllLoaded] = useState(false);

  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const { getRegistrations, registerAirdrop } = useRegistrationStore();

  const getRegistrationsCb = useCallback(() => {
    getRegistrations().then((result) => {
      if (!result) return;
      setTotalRegistration(result);
    });
  }, [getRegistrations]);

  useEffect(() => {
    getRegistrationsCb();
  }, [getRegistrationsCb]);

  useEffect(() => {
    if (!totalRegistration) return;
    setAllLoaded(true);
  }, [totalRegistration]);

  const register = async () => {
    const result = await registerAirdrop({ address });
    if (typeof result !== "string")
      return openWarningNotification("Please try again later");
    if (result === "airdrop_has_been_registered")
      return openWarningNotification("You've already registered!");
    if (result === "airdrop_registered") {
      getRegistrationsCb();
      return openSuccessNotification("You've successfully registered!");
    }
    return openWarningNotification("Please try again later");
  };

  return (
    <Container>
      <h2 className="page-title">
        "I am not a <span className="glowing-light">SLAPPER</span>, Mr. Robot. I
        am the <span className="glowing-light">SLAPPER</span> that will set you
        free."
      </h2>
      <p className="mt-[30px]">
        Get ready for a slap-tastic adventure with the How I SLAP your mother
        Airdrop! Connect your Metamask wallet securely and await the designated
        distribution date. Once the time comes, your connected wallet will be
        showered with glorious $SLAP tokens. Brace yourself for the epic slap of
        generosity coming your way! Stay tuned and get ready to embrace the
        slap-tastic fun of How I SLAP your mother!
      </p>
      <p className="mt-[15px]">
        5% of SLAP will be airdropping to 20 addresses.
      </p>
      {!allLoaded && (
        <div className="mt-[30px] flex justify-center">
          <LoadingIcon />
        </div>
      )}

      {allLoaded && (
        <>
          {/* Current Registration */}
          <h2 className="page-title mt-[30px] text-center">
            Current Registration{" "}
            <span className="glowing-light">{totalRegistration}</span>
          </h2>
          {/* Registration Button */}
          <div className="mt-[30px] text-center">
            {!address && <button onClick={() => open()}>Connect Wallet</button>}
            {address && (
              <>
                <button onClick={() => register()}>Register Now</button>
                <p
                  className="mt-[15px] cursor-pointer"
                  onClick={() => disconnect()}
                >
                  Disconnect
                </p>
              </>
            )}
          </div>
        </>
      )}
    </Container>
  );
};

export const SocialMenu = (props: any) => {
  const MENU = [
    {
      name: "X",
      icon: "x-logo.png",
      url: "https://twitter.com/N3SLAP",
    },
  ];
  return (
    <div className="flex flex-row flex-1 justify-center items-center">
      {MENU.map((item, i) => {
        return (
          <div key={i}>
            <img
              src={`/assets/${item.icon}`}
              alt={item.name}
              className="w-[20px] cursor-pointer"
              onClick={() => window.open(item.url)}
            />
          </div>
        );
      })}
    </div>
  );
};

import { Container } from "../components";

const STEPS = [
  {
    imgUrl: "/assets/metamask.webp",
    imgAlt: "metamask",
    title: "Install Wallet",
    desc: "Install the Metamask wallet extension on your web browser and create an account.",
  },
  {
    imgUrl: "/assets/eth.png",
    imgAlt: "eth",
    title: "Fund your wallet",
    desc: "Add some funds to your Metamask wallet by purchasing Ethereum (ETH) from a reliable exchange.",
  },
  {
    imgUrl: "/assets/uniswap.png",
    imgAlt: "uniswap",
    title: "DEX",
    desc: "Visit a supported decentralized exchange (DEX) platform like Uniswap.",
  },
  {
    imgUrl: "/assets/connect.png",
    imgAlt: "connect",
    title: "Connect Wallet",
    desc: "Connect your Metamask wallet to the DEX platform by clicking on the wallet icon and selecting Metamask.",
  },
  {
    imgUrl: "/assets/slap_icon.png",
    imgAlt: "slap",
    title: "Swap",
    desc: "In the swap interface, enter the amount of ETH you want to exchange for $SLAP tokens.",
  },
];

export const HowToBuy = (props: any) => {
  return (
    <Container>
      <h2 className="page-title">
        "Winter is coming, and so is the{" "}
        <span className="glowing-light">SLAP</span> of justice."
      </h2>
      <p className="mt-[30px]">
        To buy $SLAP tokens, you'll need to follow these simple steps using the
        Metamask wallet as an example
      </p>
      <div className="mt-[30px]">
        {STEPS.map((item, i) => {
          return (
            <div
              className="mb-[15px] border-[1px] rounded-[10px] flex flex-row min-h-[120px] items-center p-[30px]"
              key={i}
            >
              {/* Icon */}
              <img
                src={item.imgUrl}
                alt={item.imgAlt}
                className="h-[60px] mr-[30px]"
              />
              {/* Title + Description */}
              <div className="flex flex-col">
                <h2>{item.title}</h2>
                <p className="mt-[5px]">{item.desc}</p>
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export const errorHandling = (e: any) => {
  const msg = e?.response?.data?.msg || null;
  switch (msg) {
    case "jwt_unauthorized":
      sessionStorage.removeItem("n3-slap-token");
      return;
    default:
      break;
  }
  return msg;
};

export const isResultSuccess = (result: any) => {
  return result && result["data"] && result["data"]["success"];
};

export const refreshNewToken = (result: any) => {
  const token = result["data"]["refreshToken"] || null;
  if (!token) return;
  return sessionStorage.setItem("n3-slap-token", token);
};

export const setHeader = () => {
  let config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("n3-slap-token")}`,
    },
  };

  return config;
};

export const tcTranslation = {
  home: {
    title: "數字新時代",
    liner:
      "我們引領Web2向Web3的轉變，打造創新解決方案，涵蓋 #社交金融、#遊戲金融、#去中心化金融等領域，同時提供專業的咨詢服務。",
  },
  button: {
    slapSomeone: "掌摑他吧",
  },
  menu: {
    projects: "項目",
  },
};

import { Router, Switch, Route, Redirect } from "react-router";
import { history } from "./stores";

// Components
import { Home } from "./pages";
import { About } from "./pages/about";
import { HowToBuy } from "./pages/how-to-buy";
import { Tokenomics } from "./pages/tokenomics";
import { Roadmap } from "./pages/roadmap";
import { Register } from "./pages/register";

export default function Routers() {
  return (
    <Router history={history}>
      <Switch>
        <Route path={"/"} exact component={Home} />
        <Route path={"/about"} exact component={About} />
        <Route path={"/how-to-buy"} exact component={HowToBuy} />
        <Route path={"/tokenomics"} exact component={Tokenomics} />
        <Route path={"/roadmap"} exact component={Roadmap} />
        <Route path={"/airdrop-registration"} exact component={Register} />
        <Route path="*">
          <Redirect from={"/"} to={"/"} />
        </Route>
      </Switch>
    </Router>
  );
}

import { useState } from "react";
import { history } from "src/stores";
import { MobileMenu } from "./mobile-menu";
import i18n from "src/i18n";
import { t } from "i18next";

export const Header = (props: any) => {
  // eslint-disable-next-line
  const lang = sessionStorage.getItem("n3slap-language") || "en";
  const MENU = [
    {
      name: "home",
      url: "/",
    },
    {
      name: "about",
      url: "/about",
    },
    {
      name: "howToBuy",
      url: "/how-to-buy",
      activeName: "how-to-buy",
    },
    {
      name: "tokenomics",
      url: "/tokenomics",
    },
    {
      name: "roadmap",
      url: "/roadmap",
    },
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // eslint-disable-next-line
  const changeLang = (lang: string) => {
    sessionStorage.setItem("n3slap-language", lang);
    i18n.changeLanguage(lang);
    window.location.reload();
  };

  return (
    <div className="fixed bg-transparent w-full px-[30px] py-[30px] flex flex-row align-center items-center z-[100]">
      <div className="flex flex-row align-center items-center flex-1 ">
        <h2
          className="cursor-pointer text-[18px]"
          onClick={() => history.push("/")}
        >
          N3
        </h2>
      </div>

      {/* Menu */}
      <div className="flex flex-row justify-center items-center">
        {MENU.map((item, i) => {
          const name = item.activeName || item.name;
          const isCurrentPage =
            window.location.pathname.indexOf(name.toLowerCase()) > -1;
          return (
            <div key={i} className="pl-[30px] desktop">
              <p
                className={`cursor-pointer hover:text-primary transition-all duration-500 ${
                  isCurrentPage ? "text-primary" : ""
                }`}
                onClick={() => item.url && history.push(item.url)}
              >
                {t(`menu.${item.name}`)}
              </p>
            </div>
          );
        })}
        {/* Change Lang - Hide for now */}
        {/* <div className="pl-[30px]">
          <p
            className={`cursor-pointer hover:text-primary transition-all duration-500`}
            onClick={() => changeLang(lang === "en" ? "tc" : "en")}
          >
            {lang === "en" ? "中" : "EN"}
          </p>
        </div> */}
        {/* Mobile Menu Icon */}
        <div className="pl-[30px]">
          <img
            src="/assets/menu.png"
            alt="menu"
            className="h-[20px] cursor-pointer"
            onClick={() => setIsMenuOpen(true)}
          />
        </div>
      </div>

      {/* Mobile Menu */}
      <MobileMenu
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        menu={MENU}
      />
    </div>
  );
};

import { Container } from "../components";

const STEPS = [
  {
    imgUrl: "/assets/slap_icon.png",
    imgAlt: "slap",
  },
  {
    imgUrl: "/assets/metamask.webp",
    imgAlt: "metamask",
  },
  {
    imgUrl: "/assets/eth.png",
    imgAlt: "eth",
  },
  {
    imgUrl: "/assets/uniswap.png",
    imgAlt: "uniswap",
  },
];

export const Tokenomics = (props: any) => {
  return (
    <Container>
      <h2 className="page-title">
        "The one where the <span className="glowing-light">SLAP</span> keeps us
        together, forever."
      </h2>
      <p className="mt-[30px]">
        The $SLAP token has a maximum supply of 10,000,000,000 tokens.
      </p>
      <p className="mt-[15px]">
        The token follows the ERC20 standard, which ensures its compatibility
        with various wallets and decentralized exchanges.
      </p>
      {/* Icons block */}
      <div className="mt-[30px] grid grid-cols-2 md:grid-cols-4 gap-[15px]">
        {STEPS.map((item, i) => {
          return (
            <img
              src={item.imgUrl}
              alt={item.imgAlt}
              className={$imgStyle}
              key={i}
            />
          );
        })}
      </div>
    </Container>
  );
};

const $imgStyle = "w-[75px]";

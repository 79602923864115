import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { configureChains, createConfig } from "wagmi";
import { mainnet } from "wagmi/chains";
require("dotenv").config();

export const API_URL =
  process.env.REACT_APP_API_URL || "http://localhost:30124";
export const NETWORK = process.env.REACT_APP_NETWORK || "testnet";

// Web3 Modal
export const projectId = "bbdad8334bf7190ebdac1c35573a98ed";
const chains = [mainnet];
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

// Wagmi client
export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});

// Web3Modal Ethereum Client
export const ethereumClient = new EthereumClient(wagmiConfig, chains);

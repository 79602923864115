import { Container } from "../components";

export const About = (props: any) => {
  return (
    <Container>
      <h2 className="page-title">
        "May the <span className="glowing-light">SLAP</span> be with you,
        always."
      </h2>
      <p className="mt-[30px]">
        "How I SLAP your mother" is a unique project in the Crypto World that
        aims to bring humor and entertainment to the blockchain space. It is
        based on the popular internet meme culture and allows users to
        participate in a fun and light-hearted community centered around the
        idea of playful slaps.
      </p>
      {/* Images block */}
      <div className="mt-[30px] grid grid-cols-2 md:grid-cols-4 gap-[15px]">
        <img
          src="/assets/elon_disney.png"
          alt="sample-img"
          className={$imgStyle}
        />
        <img
          src="/assets/x_disney.png"
          alt="sample-img"
          className={$imgStyle}
        />
      </div>
    </Container>
  );
};

const $imgStyle = "w-[200px]";

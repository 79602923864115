import axios from "axios";
import * as Configs from "../config";
import { errorHandling, setHeader } from "./api";

export class RegistrationStore {
  async getRegistrations() {
    const url = `${Configs.API_URL}/airdrop/registration-total`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async registerAirdrop(payload: any) {
    const url = `${Configs.API_URL}/airdrop/register`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      return result["data"]["msg"];
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_REGISTRATION = "registrationStore";
